<template>
  <section>
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm1"
          :model="selectForm1"
          class="select_form"
        >
          <el-form-item label="年:">
            <el-input v-model="selectForm1.Year" placeholder="年"></el-input>
          </el-form-item>
          <el-form-item label="月:">
            <el-input v-model="selectForm1.Month" placeholder="月"></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="new_css calc-height">
      <!--列表-->
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
        @row-dblclick="handleEdit"
        @current-change="selectCurrentRow"
        class="new_table"
      >
        <el-table-column prop="CreateUserName" label="创建人">
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="CreateDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column label="年" prop="Year"></el-table-column>
        <el-table-column label="月" prop="Month"></el-table-column>
        <el-table-column label="状态" prop="State">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.State == '未锁定' ? 'success' : 'danger'"
              disable-transitions
            >
              {{ scope.row.State }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      title="编辑"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryForm"
        :model="SakaryForm"
        class="demo-form-inline"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名:" prop="UserName">
              <el-input
                type="text"
                v-model="SakaryForm.UserName"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="金额" prop="Salary">
              <el-input
                type="text"
                v-model="SakaryForm.Salary"
                @blur="inputMoney('Salary')"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
               <el-form-item label="绩效类型:" prop="Type">
            <el-select v-model="SakaryForm.Type" placeholder="请选择">
              <el-option label="绩效月度" value="0"> </el-option>
              <el-option label="绩效季度" value="1"> </el-option>
              <el-option label="绩效半年" value="2"> </el-option>
              <el-option label="绩效年终" value="3"> </el-option>
            </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注:" prop="Remark">
              <el-input type="text" v-model="SakaryForm.Remark"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: center">
            <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
              >提交</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
      title="绩效月度"
      :visible.sync="InsuranceVisible"
      v-model="InsuranceVisible"
      :close-on-click-modal="false"
      width="80%"
    >
      <el-row>
          <el-col v-if="buttonList.length > 0"
                  :span="24"
                  class="organizetoolbar"
                  style="padding-bottom: 0px">
              <el-form :inline="true" ref="selectForm" :model="selectForm">
                  <el-form-item label="名称:">
                      <el-input v-model="selectForm.userName"
                                placeholder="名称"></el-input>
                  </el-form-item>
                  <el-form-item label="发放公司名称:">
                      <el-select style="width: 100%"
                                 clearable
                                 v-model="selectForm.DistributionFactory"
                                 placeholder="发放公司名称">
                          <el-option v-for="item in PositionLists"
                                     :key="item.name"
                                     :label="item.name"
                                     :value="item.name"></el-option>
                      </el-select>
                  </el-form-item>
                  <el-form-item label="公司名称:">
                      <el-input v-model="selectForm.factory" placeholder="公司名称"></el-input>
                  </el-form-item>
                  <!-- 这个就是当前页面内，所有的btn列表 -->
                  <el-form-item>
                      <!-- 这里触发点击事件 -->

                      <el-button type="primary"
                                 size="mini"
                                 icon="el-icon-search"
                                 @click="getInsuranceData()">查询</el-button>
                      <el-button size="mini"
                                 type="primary"
                                 icon="el-icon-edit"
                                 :disabled="isDisabled"
                                 @click="handleInEdit()">修改</el-button>
                      <el-button size="mini"
                                 type="primary"
                                 icon="el-icon-delete"
                                 :disabled="isDisabled"
                                 @click="handleInDel()">删除</el-button>
                      <el-button size="mini"
                                 type="primary"
                                 icon="el-icon-edit"
                                 :disabled="isDisabled"
                                 @click="handleCommany()">提交公司</el-button>
                      <el-button size="mini"
                                 type="primary"
                                 icon="el-icon-edit"
                                 @click="handleExcelTax()">导出excel</el-button>
                  </el-form-item>
              </el-form>
              <el-upload action="https://api.gradgroup.cn/ftp/ftp/upload"
                         :headers ="token"
                         :on-change="handleChange"
                         :file-list="fileListUpload"
                         :show-file-list="false"
                         :limit="1"
                         accept=".xlsx,.xls"
                         :auto-upload="false">
                  <el-button :loading="disbtn"
                             size="small"
                             :disabled="isDisabled"
                             type="primary">上传 Excel</el-button>
              </el-upload>
          </el-col>
      </el-row>
      <el-table :data="InsuranceData"
                highlight-current-row
                border
                height="500"
                @current-change="selectCurrentRow1"
                @selection-change="handleSelectionChange"
                class="new_table">
          <el-table-column fixed prop="UserName" label="名称"> </el-table-column>
          <el-table-column prop="RegYear" label="年"> </el-table-column>
          <el-table-column prop="RegMonth" label="月"> </el-table-column>
          <el-table-column label="发放公司" show-overflow-tooltip width="150" prop="DistributionFactory"></el-table-column>
          <el-table-column label="公司" show-overflow-tooltip width="150" prop="Factory"></el-table-column>
          <el-table-column label="部门"
                           show-overflow-tooltip
                           width="150"
                           prop="UserDpt"></el-table-column>
          <el-table-column label="岗位"
                           show-overflow-tooltip
                           width="150"
                           prop="UserPost"></el-table-column>
          <el-table-column label="金额"
                           show-overflow-tooltip
                           width="150"
                           prop="Salary"></el-table-column>
          <el-table-column label="类型" prop="Type">
              <template slot-scope="scope">
                  <span v-if="scope.row.Type == 1">绩效季度</span>
                  <span v-else-if="scope.row.Type == 2"> 绩效半年</span>
                  <span v-else-if="scope.row.Type == 3"> 绩效年终</span>

                  <span v-else-if="scope.row.Type == 0"> 绩效月度</span>
              </template>
          </el-table-column>
          <el-table-column label="状态" prop="State">
              <template slot-scope="scope">
                  <span v-if="scope.row.State == 1">未提交</span>
                  <span v-else-if="scope.row.State == 2"> 待审核</span>
                  <span v-else-if="scope.row.State == 3"> 已审核</span>
                  <span v-else-if="scope.row.State == 4"> 已发放</span>
                  <span v-else-if="scope.row.State == 0"> 已退回</span>
              </template>
          </el-table-column>
          <el-table-column label="备注"
                           show-overflow-tooltip
                           width="150"
                           prop="Remark"></el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        align="center"
        @current-change="handleCurrentChange1"
        :current-page="currentPage1"
        :page-size="pageSize1"
        layout="prev, pager, next, jumper ,total"
        :page-count="total1"
        :total="totaldata1"
      >
      </el-pagination>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
//  import moment from 'moment/moment.js'
import * as validate from "../../../util/validate";
import {
  UpdateSalaryExcelMonth,
  GetSalaryExcelList,
  MeritSalaryInsertInfo,
  MeritSalaryListDelInfo,
  UpdateSalaryExcel,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar },
  data() {
      return {
          token: { "Authorization": "Bearer " + window.localStorage.getItem("access_token") },
      PositionLists: [],
      fileListUpload: [],
      searchVal: "",
      disbtn: false,
      loading: true,
      c_row: null,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      currentPage1: 1, // 当前页码
      total1: null, // 总条数
      totaldata1: null,
      pageSize1: 20, // 每页的数据条数
      page1: 1,
      isDisabled: false,
      SakaryVisible: false,
      InsuranceVisible: false,
      SakaryAllFormVisible: false,
      buttonListmsg: "",
      users: [],
      InsuranceData: [],
      operation: false, // true:新增, false:编辑
      buttonList: [],
      multipleSelection: [],
      PId: "",
      selectForm: {
        userName: "",
          factory: "",
          DistributionFactory:"",
        year: "",
      },
      selectForm1: {
        Year: "",
        Month: "",
      },
      SakaryForm: [],
      filters: {
        name: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectCurrentRow1(val) {
      this.currentRow1 = val;
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.page1 = val;
      this.getInsuranceData(false);
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    handleCommany() {
      let row = this.currentRow1;
        if (!this.selectForm.DistributionFactory) {
        this.$message({
          message: "请选择设置的公司！",
          type: "error",
        });
        return;
      }
      let para = {
        PId: this.PId,
          distributionFactory: this.selectForm.DistributionFactory,
        state: "提交",
      };
      this.$api.MeritSalaryListSubmitInfo(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "设置成功",
            type: "success",
            duration: 5000,
          });
          this.getInsuranceData();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        Year: this.selectForm1.Year,
        Month: this.selectForm1.Month,
      };

      this.$api.MeritSalaryGetInfoPageList(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getGroupFactoryList() {
      let para = {
        PId: this.PId,
      };
      this.$api.MeritSalaryListGetGroupFactoryList(para).then((res) => {
        this.PositionLists = res.data.response;
      });
    },
    getInsuranceData(isCurrent = true) {
      if (isCurrent) {
        this.page1 = 1;
      }
      console.log(this.c_row);
      let para = {
        page: this.page1,
        size: this.pageSize1,
        PId: this.PId,
        userName: this.selectForm.userName,
          distributionFactory: this.selectForm.DistributionFactory,
          factory: this.selectForm.factory,
        //month: this.c_row.Month,
        //year: this.c_row.Year,
      };
      this.$api.MeritSalaryListGetInfoPageList(para).then((res) => {
        console.log(res.data);
        this.total1 = res.data.response.pageCount;
        this.totaldata1 = res.data.response.dataCount;
        this.InsuranceData = res.data.response.data;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleExcelTax() {
      this.$api
        .MeritSalaryGetPersonHistoryList({
          monthId: this.PId,
          factory: this.selectForm.factory,
        })
        .then((res) => {
          if (res.data.success) {
            console.log(res);
            var header = [
              "年",
              "月",
                "姓名",
                "发放公司",
              "公司",
              "部门",
              "岗位",
              "金额",
              "类型",
              "状态",
              "备注",
            ];
            var filter = [
              "RegYear",
              "RegMonth",
                "UserName",
              "DistributionFactory",
              "Factory",
              "UserDpt",
              "UserPost",
              "Salary",
              "Type",
              "State",
              "Remark",
            ];
            var dataName = util.formatDate.format(
              new Date(new Date()),
              "yyyyMMddhhmm"
            );
            var name = "绩效月度" + dataName;
            this.excelData(res.data.response, header, filter, name);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
        });
    },
    excelData(res, header, filter, name) {
      require.ensure([], () => {
        const {
          export_json_to_excel,
        } = require("../../../util/Export2Excel.js");
        const tHeader = header;
        const filterVal = filter;
        const list = res;

        res.forEach((item) => {
          console.log(item);
          if (item.Type == 0) {
            item.Type = "绩效月度";
          } else if (item.InRange == 1) {
            item.Type = "绩效季度";
          } else if (item.InRange == 2) {
            item.Type = "绩效半年";
          } else if (item.InRange == 3) {
            item.Type = "绩效年终";
          }

          if (item.State == 0) {
            item.State = "已退回";
          } else if (item.State == 1) {
            item.State = "未提交";
          } else if (item.State == 2) {
            item.State = "待审核";
          } else if (item.State == 3) {
            item.State = "已审核";
          } else if (item.State == 4) {
            item.State = "已发放";
          }
        });
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, name);
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.State == "未锁定") {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }

      this.PId = row.Id;
      this.c_row = row;
      this.getInsuranceData(row);
      this.getGroupFactoryList();
      this.InsuranceVisible = true;
    },
    handleInEdit() {
      let row = this.currentRow1;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      if(row.State >1){
         this.$message({
          message: "已审核的数据不允许修改！",
          type: "error",
        });
        return;
      }
      this.SakaryForm = Object.assign({}, row);
      this.SakaryForm.Type = String(this.SakaryForm.Type)
      this.SakaryVisible = true;
    },

    handleAdd() {
      this.$api.MeritSalaryAddInfo().then((res) => {
        if (res.data.success) {
          this.$message({
            message: "添加成功",
            type: "success",
            duration: 5000,
          });
          this.SakaryVisible = false;
          this.getData();
        } else {
          this.$message({
            message: "添加失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.State == "已锁定") {
        this.$message({
          message: "锁定状态的数据不允许删除！",
          type: "error",
        });
        return;
      }
      let para = {
        id: row.Id,
      };

      this.$api.MeritSalaryDelInfo(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 5000,
          });
          this.SakaryVisible = false;
          this.getData(false);
        } else {
          this.$message({
            message: "删除失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleInDel() {
      let row = this.currentRow1;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
        });
        return;
      }

      let para = {
        id: row.Id,
      };

        this.$api.MeritSalaryListDelInfo(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 5000,
          });

          this.getInsuranceData();
        } else {
          this.$message({
            message: "删除失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleLock() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要锁定的一行数据！",
          type: "error",
        });
        return;
      }
      this.$confirm("确定要锁定吗？", "提示", {}).then(() => {
        this.$api.MeritSalarySetInfoSate({ id: row.Id }).then((res) => {
          console.log(res);
          if (res.data.success) {
            this.$message({
              message: "修改成功",
              type: "success",
              duration: 5000,
            });
            this.getData(false);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
        });
      });
    },
    handleDelLock() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要解锁的一行数据！",
          type: "error",
        });
        return;
      }
      this.$confirm("确定要解锁吗？", "提示", {}).then(() => {
        UpdateSalaryExcelMonth({ id: row.Id, State: "解锁" }).then((res) => {
          console.log(res);
          if (res.data.success) {
            this.$message({
              message: "修改成功",
              type: "success",
              duration: 5000,
            });
            this.getData(false);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
        });
      });
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    inputAllMoney(name) {
      if (!validate.validateIsMoney(this.SakaryAllForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.SakaryForm);
          this.$api.MeritSalaryListUpdateInfo(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 5000,
              });
              this.SakaryVisible = false;
              this.getInsuranceData();
            } else {
              this.$message({
                message: "修改失败",
                type: "error",
                duration: 5000,
              });
            }
          });
        });
      });
    },
    doDown() {
      let hrefStr = encodeURI("/api/SalaryExcel/DownloadTemplate");
      window.location.href = hrefStr;
    },

    // 上传附件
    handleChange(file, fileList) {
      console.log(this.PId);
      this.fileTemp = file.raw;
      (this.disbtn = true), this.importfxx(this.fileTemp, this.PId);
    },
    // Excel 转化
    importfxx(obj, pid) {
      let _this = this;
      // 通过DOM取文件数据
      this.file = obj;
      var rABS = false; // 是否将文件读取为二进制字符串
      var f = this.file;
      var reader = new FileReader();
      // if (!FileReader.prototype.readAsBinaryString) {
      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = "";
        var rABS = false; // 是否将文件读取为二进制字符串
        var pt = this;
        var wb; // 读取完成的数据
        var outdata;
        var reader = new FileReader();
        reader.onload = function (e) {
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          var XLSX = require("xlsx");
          if (rABS) {
            wb = XLSX.read(btoa(fixdata(binary)), {
              // 手动转化
              type: "base64",
            });
          } else {
            wb = XLSX.read(binary, {
              type: "binary",
            });
          }
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]); // outdata就是你想要的东西
          this.da = [...outdata];
          let arr = [];
          this.da.map((v) => {
            console.log(v);
            let obj = {};
              obj.IDCard = v["身份证号"];
              obj.DistributionFactory = v["发放公司"];
              obj.Type = v["类型"];
              obj.Salary = v["实发奖金"];
              obj.Remark = v["备注"];
              
            obj.PId = pid;
            arr.push(obj);
            _this.list = [...arr];
          });
          _this.ExcelData(arr);
        };

        reader.readAsArrayBuffer(f);
      };
      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
      ExcelData(arr) {
          MeritSalaryInsertInfo(arr).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "导入成功",
            type: "success",
            duration: 5000,
          });
          this.disbtn = false;
          this.getInsuranceData();
        } else {
          this.$message({
            message: res.data.masg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
</style>

